import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import unidecode from 'unidecode';

import { QuickActionData } from 'src/data/stores/actions/quick-actions.store.interface';
import {
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';

import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { ProgressIndicator } from 'src/presentation/shared/progress-indicator/progress-indicator.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

import { ContactExtractionCandidatesComponent } from './contract-extraction-candidates.component';
import {
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';
import { Button } from '@buildingradar/ui_library/button';

interface ContactExtractionDrawerContentComponentProps {
    isOpened: boolean;
    extractionStatus?: ContactExtractionResultStatus;
    contactCandidates: DealContactCandidate[];
    companyCandidates: DealCompanyCandidate[];
    dealId: string;
    extractingInProgress: boolean;
    createContactInProgress: boolean;
    onPauseDealRequested: (launchSource: ActionFeatureLaunchSource) => void;
    requestClose: () => void;
    rerunAutoExtraction: (dealId: string) => void;
    getAutoExtractionResult: (dealId: string) => void;
    setQuickAction: (data: QuickActionData) => void;
    createContactsFromCandidates: (
        contactCandidates: DealContactCandidate[],
        companyCandidates: DealCompanyCandidate[],
        dealId: string,
        isKey: boolean,
    ) => void;
}

export const ContactExtractionDrawerContentComponent: FC<ContactExtractionDrawerContentComponentProps> =
    observer(
        ({
            isOpened,
            contactCandidates,
            companyCandidates,
            extractionStatus,
            createContactInProgress,
            dealId,
            extractingInProgress,
            onPauseDealRequested,
            requestClose,
            getAutoExtractionResult,
            rerunAutoExtraction,
            setQuickAction,
            createContactsFromCandidates,
        }) => {
            const { t } = useTranslation();
            const isMobile = useMediaQuery(MediaQuery.phone);
            const [selectedCandidates, setSelectedCandidates] = useState<
                DealContactCandidate[]
            >([]);
            const [selectedCompanyCandidates, setSelectedCompanyCandidates] =
                useState<DealCompanyCandidate[]>([]);

            const contactExtractionRunning =
                extractingInProgress ||
                extractionStatus === ContactExtractionResultStatus.InProgress;

            useEffect(() => {
                if (
                    isOpened &&
                    extractionStatus === ContactExtractionResultStatus.NeverRun
                ) {
                    rerunAutoExtraction(dealId);
                }
            }, [
                dealId,
                extractionStatus,
                getAutoExtractionResult,
                isOpened,
                rerunAutoExtraction,
            ]);

            useEffect(() => {
                if (
                    extractionStatus ===
                        ContactExtractionResultStatus.Success &&
                    contactCandidates.length !== 0
                ) {
                    setSelectedCandidates([contactCandidates[0]]);
                }
            }, [contactCandidates, contactCandidates.length, extractionStatus]);

            const close = useCallback(() => {
                requestClose();
            }, [requestClose]);

            const onSelect = useCallback(
                (selected: boolean, candidate: DealContactCandidate) => {
                    setSelectedCandidates((candidates) => {
                        const list = candidates.slice();
                        if (selected) {
                            list.push(candidate);
                        } else {
                            const index = list.findIndex(
                                (val) => val === candidate,
                            );
                            if (index >= 0) {
                                list.splice(index, 1);
                            }
                        }
                        return list;
                    });
                },
                [],
            );

            const onSelectCompany = useCallback(
                (selected: boolean, candidate: DealCompanyCandidate) => {
                    setSelectedCompanyCandidates((candidates) => {
                        const list = candidates.slice();
                        if (selected) {
                            list.push(candidate);
                        } else {
                            const index = list.findIndex(
                                (val) => val === candidate,
                            );
                            if (index >= 0) {
                                list.splice(index, 1);
                            }
                        }
                        return list;
                    });
                },
                [],
            );

            const onClickSave = useCallback(
                (isKey: boolean) => {
                    const contactCompanies = selectedCandidates
                        .map((contact) =>
                            companyCandidates.find(
                                ({ name }) =>
                                    unidecode(name).toLowerCase() ===
                                    unidecode(
                                        contact.companyName,
                                    ).toLowerCase(),
                            ),
                        )
                        .filter(isNonNullable);

                    const companies = Array.from(
                        new Set([
                            ...selectedCompanyCandidates,
                            ...contactCompanies,
                        ]),
                    );
                    createContactsFromCandidates(
                        selectedCandidates,
                        companies,
                        dealId,
                        isKey,
                    );
                    close();
                },
                [
                    selectedCandidates,
                    createContactsFromCandidates,
                    selectedCompanyCandidates,
                    dealId,
                    close,
                    companyCandidates,
                ],
            );

            const disableSave = useMemo(
                () =>
                    createContactInProgress ||
                    (selectedCandidates.length === 0 &&
                        selectedCompanyCandidates.length === 0),
                [
                    createContactInProgress,
                    selectedCandidates.length,
                    selectedCompanyCandidates.length,
                ],
            );

            const openSetReminder = useCallback(() => {
                setQuickAction({
                    entity: {
                        description: t(
                            'enablement_panel.contact_extraction.failure.reminder_placeholder',
                        ),
                    },
                    launchSource:
                        ActionFeatureLaunchSource.EnablementPanelContactExtraction,
                    dealId,
                    quickAction: ActionFeaturesIdentifiers.schedule_reminder,
                });
            }, [dealId, setQuickAction, t]);

            const handleOnPauseDealRequested = useCallback(() => {
                requestClose();
                onPauseDealRequested(
                    ActionFeatureLaunchSource.EnablementPanelContactExtraction,
                );
            }, [onPauseDealRequested, requestClose]);

            const onRetry = useCallback(() => {
                rerunAutoExtraction(dealId);
            }, [dealId, rerunAutoExtraction]);

            const getExceptionIcon = useCallback(
                (status?: ContactExtractionResultStatus) => {
                    const isError =
                        status === ContactExtractionResultStatus.Error;
                    return (
                        <div className="flex size-full flex-col items-center justify-center gap-2 p-5">
                            <div className="flex size-12 shrink-0 items-center justify-center rounded-full bg-yellow-100">
                                <BrIcon
                                    iconColor={'var(--yellow-90)'}
                                    iconName={
                                        isError
                                            ? IconName.BrSyncProblemIcon
                                            : IconName.BrPersonNotFoundIcon
                                    }
                                    iconSize={42.5}
                                />
                            </div>
                            <div>
                                {t(
                                    isError
                                        ? 'common.service_unavailable'
                                        : 'enablement_panel.contact_extraction.failure.no_contacts',
                                )}
                            </div>
                            <div className="flex w-full flex-row items-center justify-center gap-5">
                                <Button variant="ghost" onClick={onRetry}>
                                    {t('common.try_again')}
                                </Button>
                                {isError && (
                                    <Button
                                        variant="ghost"
                                        onClick={openSetReminder}
                                    >
                                        {t(
                                            'enablement_panel.contact_extraction.failure_action.set_reminder',
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                },
                [onRetry, openSetReminder, t],
            );

            const centerContent = useMemo(() => {
                if (contactExtractionRunning || createContactInProgress) {
                    return (
                        <ProgressIndicator
                            fullHeight
                            hint={t(
                                createContactInProgress
                                    ? 'enablement_panel.contact_extraction.saving_status_text'
                                    : 'enablement_panel.contact_extraction.auto_extracting_status_text',
                            )}
                        />
                    );
                } else if (
                    extractionStatus === ContactExtractionResultStatus.Success
                ) {
                    return (
                        <ContactExtractionCandidatesComponent
                            onSelectionChange={onSelect}
                            onSelectCompany={onSelectCompany}
                            contactCandidates={contactCandidates}
                            companyCandidates={companyCandidates}
                            status={extractionStatus}
                        />
                    );
                } else {
                    return getExceptionIcon(extractionStatus);
                }
            }, [
                contactExtractionRunning,
                createContactInProgress,
                extractionStatus,
                t,
                onSelect,
                onSelectCompany,
                contactCandidates,
                companyCandidates,
                getExceptionIcon,
            ]);

            return (
                <ActionContentComponent
                    contentSxOverride={{ width: isMobile ? '100%' : '400px' }}
                    title={t('enablement_panel.contact_extraction.title')}
                    body={centerContent}
                    actions={
                        <>
                            {extractionStatus ===
                                ContactExtractionResultStatus.NoContacts && (
                                <Button
                                    variant="outline"
                                    onClick={handleOnPauseDealRequested}
                                >
                                    {t(
                                        'enablement_panel.contact_extraction.failure_action.pause_deal',
                                    )}
                                </Button>
                            )}
                            {extractionStatus ===
                                ContactExtractionResultStatus.Success && (
                                <Button
                                    onClick={() => onClickSave(true)}
                                    disabled={disableSave}
                                >
                                    {t(
                                        'enablement_panel.contact_extraction.save_button_text',
                                        {
                                            value:
                                                selectedCandidates.length +
                                                selectedCompanyCandidates.length,
                                            interpolation: {
                                                escapeValue: false,
                                            },
                                        },
                                    )}
                                </Button>
                            )}
                            <Button variant="outline" onClick={close}>
                                {t('common.close')}
                            </Button>
                        </>
                    }
                    onClose={close}
                />
            );
        },
    );
