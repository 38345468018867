import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';

import { Language } from 'src/domain/models/locale/locale.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { IContactExtractionFeature } from './contact-extraction-interface.feature';
import { IContactExtractionApi } from '../../data/contact-extraction.api';
import {
    ContactExtractionResult,
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from '../../data/contact-extraction.model';
import { IContactExtractionStore } from '../../data/store/contact-extraction.interface.store';
import { doNothing } from 'src/utils/function.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { encodeStringHighlightToUrl } from 'src/utils/url.utils';

export class ContactExtractionFeature implements IContactExtractionFeature {
    get isFeatureEnabled() {
        return this.accountConfigurationStore.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.contact_extractor,
        );
    }

    get contactExtractionResultStatus():
        | ContactExtractionResultStatus
        | undefined {
        return this.contactExtractionStore.contactExtractionResultStatus;
    }

    get contactExtractionCandidates(): DealContactCandidate[] {
        return this.contactExtractionStore.contactExtractionCandidates;
    }

    get companyExtractionCandidates(): DealCompanyCandidate[] {
        return this.contactExtractionStore.companyExtractionCandidates;
    }

    extractionInProgress = false;

    getAutoExtractionResult = async (dealId: string) => {
        this.setContactExtractionResult(undefined);
        this.setContactExtractionResultStatus(
            ContactExtractionResultStatus.InProgress,
        );
        const response = await handleRequestAsync(
            this.contactExtractionApi.getAutoExtractionResult,
            { dealId },
            doNothing,
            (error) => {
                this.baseStore.onRequestFailed(
                    'request-auto-extract-contacts',
                    error,
                );
                error &&
                    this.setContactExtractionResultStatus(
                        ContactExtractionResultStatus.Error,
                    );
            },
            'getAutoExtractionResult',
        );
        if (response) {
            this.setContactExtractionResult(response);
        }
    };

    rerunAutoExtraction = async (dealId: string) => {
        await handleRequestAsync(this.contactExtractionApi.runAutoExtraction, {
            dealId,
            language: this.userStore.user?.language ?? Language.De,
        });
        this.getAutoExtractionResult(dealId);
    };

    setContactExtractionCandidates = (candidates: DealContactCandidate[]) => {
        this.contactExtractionStore.setContactExtractionCandidates(candidates);
    };

    setExtractingInProgress = (flag: boolean) => {
        this.extractionInProgress = flag;
    };

    clear = () => {
        this.contactExtractionStore.clear();
    };

    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private contactExtractionApi: IContactExtractionApi,
        private contactExtractionStore: IContactExtractionStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
    ) {
        makeAutoObservable(this);
    }

    setContactExtractionResultStatus = (
        status?: ContactExtractionResultStatus,
    ) => {
        this.contactExtractionStore.setContactExtractionResultStatus(status);
    };

    setCompanyExtractionCandidates = (candidates: DealCompanyCandidate[]) => {
        this.contactExtractionStore.setCompanyExtractionCandidates(candidates);
    };

    setContactExtractionResult = (result?: ContactExtractionResult) => {
        if (!result) {
            this.clear();
            return;
        }
        result.contactCandidates.forEach((item) => {
            item.sourceUrls = item.sourceUrls.map((url) =>
                encodeStringHighlightToUrl(
                    url,
                    getFullName(item.firstName, item.lastName),
                ),
            );
        });
        result.companyCandidates.forEach((item) => {
            item.sourceUrls = item.sourceUrls.map((url) =>
                encodeStringHighlightToUrl(url, item.name),
            );
        });
        this.setContactExtractionCandidates(result.contactCandidates);
        this.setCompanyExtractionCandidates(result.companyCandidates);
        this.setContactExtractionResultStatus(result.status);
    };
}
